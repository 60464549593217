export const api_endpoint = "https://acceptance.sabia-api.dyn1.payrollselect.nl";

export const bugsnag_key = "89da413eec226813988f47a2b0231e6b";
export const min_start_date = new Date("01-01-2020");
export const release_stage = "acceptance";
export const intercom_app_id = "dzmw9ffi";
export const google_analytics_id = "";
export const picNicCompanyId = "808c7fa6-8b67-4655-bb84-2ac5bc04efde";
export const easyPayrollBaseUrl = "https://acc.easypayroll.nl/";
export const easyPayrollApiUrl = "https://acc.easypayroll.nl/wp-json/dij";
export const featureSearchAddressBarEnabled = false;
export const maintenance_window_end = 1673262000;

export const appThemes = {
  "dbv-picnic-acc.payrollselect.nl": "picnic",
  "dbv-vattenfall-acc.payrollselect.nl": "vattenfall",
  "dbv-easyworx-acc.payrollselect.nl": "easyworx",
  "dbv-acc.payrollselect.nl": "payroll",
  "app-acc.easyjobs.nl": "easyjobs",
  "dbv-easypayroll-acc.payrollselect.nl": "easypayroll",
  "dbv-gorillas-acc.payrollselect.nl": "gorillas"
};
